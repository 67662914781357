import { PageProps } from "gatsby"
import React, { ReactElement } from "react"
import { CitySelectionView, Layout, SEO } from "../components"
import * as styles from "./prefecture.module.scss"

// MARK: - ReactElement

interface PageContext {
  cities: string[]
}

export default function prefecture(
  props: PageProps<void, PageContext>
): ReactElement {
  const prefecture = decodeURI(props.path.slice(1))
  const cities = props.pageContext.cities

  return (
    <Layout>
      <SEO
        title={`${prefecture}の駐車場`}
        description={`${prefecture}全域から、Qt-netに加盟している駐車場を探すことができます。`}
        lang="ja"
      />
      <div className={styles.container}>
        <h4 className={styles.title}>{`${prefecture}の地域から探す`}</h4>
        <CitySelectionView prefecture={prefecture} cities={cities} />
      </div>
    </Layout>
  )
}
